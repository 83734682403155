import React from "react";

import Container from "@src/components/Container";
import { Flex } from "@src/components/Boxes";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import { mediaQueries } from "@src/styles/theme";
import GatsbyImageWrapper from "@src/components/GatsbyImageWrapper";

const Header = styled("h1")`
  color: #333;
  font-size: 46px;
  font-weight: 300;
  line-height: 1.1;
  margin: 25px 0 12.5px;
  font-size: 38px;

  ${mediaQueries[0]} {
    font-size: 100px;
  }
`;
const SubHeader = styled("h2")`
  color: #333;
  font-size: 31px;
  font-weight: 300;
  line-height: 1.1;
  margin-bottom: 12.5px;
  margin-top: 25px;

  ${mediaQueries[0]} {
    font-size: 38px;
  }
`;

const Fullscreen = styled("div")`
  position: absolute;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 100000;
`;

const HeaderAppUrl = styled("h1")`
  color: #333;
  font-size: 38px;
  font-weight: 300;
  line-height: 1.1;
  margin: 25px 0 12.5px;
  ${mediaQueries[0]} {
    font-size: 100px;
  }
`;

const HeaderAppUrlSub = styled("h2")`
  color: #333;
  font-size: 31px;
  font-weight: 300;
  line-height: 1.1;
  margin-bottom: 12.5px;
  margin-top: 25px;
  ${mediaQueries[0]} {
    font-size: 38px;
  }
`;

const AppOther = styled("a")`
  font-weight: 700;
  color: #18c3ff;
  :hover {
    color: #0096cb;
  }
  font-size: 14px;
  ${mediaQueries[0]} {
    font-size: 18px;
  }
`;

const AppImg = styled(GatsbyImageWrapper)`
  width: 100%;
  max-width: 480px;
`;

function Android() {
  const data = useStaticQuery(graphql`
    {
      photo: file(name: { eq: "oscars-ios" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `);

  const { photo } = data;
  return (
    <Fullscreen>
      <Container>
        <Flex justifyContent="center" paddingTop="88px">
          <Flex width={8 / 12} mx="auto" flexDirection="column">
            <Header>ClassDojo is now on iPhone, iPad and iPod Touch!</Header>

            <AppImg image={photo} alt="ClassDojo characters on red carpet" />

            <SubHeader>Just visit this URL on your device:</SubHeader>

            <HeaderAppUrl>https://itunes.apple.com/us/app/classdojo/id552602056?mt=8</HeaderAppUrl>

            <HeaderAppUrlSub>&hellip;to get the free app!</HeaderAppUrlSub>

            <AppOther href="/android/">Looking for the Android app? Get it here &rarr;</AppOther>
          </Flex>
        </Flex>
      </Container>
    </Fullscreen>
  );
}

export default Android;
